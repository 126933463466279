<template>
  <v-card rounded="lg" flat max-width="500" class="px-md-12 py-md-10 pa-4 width100" min-height="448">
    <div @click="toLogin" class="link primary--text text--lighten-1 mb-7">
      <v-icon color="primary lighten-1">mdi-chevron-left</v-icon> Back to Log In
    </div>
    <div class="f32 mb-2">Email confirmation</div>
    <div class="mb-9 text--text">Please confirm your email address</div>
    <v-btn depressed @click="action" large block color="primary">Confirm</v-btn>
  </v-card>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      error: [],
    };
  },
  methods: {
    notifi(btn) {
      if (btn == 'notFound') {
        this.$notify({
          title: 'Account not found',
          message: 'Check the email address is correct and try again.',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    toLogin() {
      this.$router.push('/login');
    },
    async action() {
      this.error = [];
      const data = new Object();
      data.token = this.$route.query.t;
      await this.$store
        .dispatch('emailConfirm', data)
        .then((res) => {
          if (res.data.auth_token) {
            localStorage.setItem('jwt_token', res.data.auth_token);
            this.$router.push('/login?c=OTP');
          } else if (res.data.access_token) {
            localStorage.setItem('jwt_token', res.data.access_token);
            setTimeout(() => {
              this.$router.push('/');
            }, 500);
          }
        })
        .catch((e) => {
          this.loginError(e);
        });
    },
    loginError(e) {
      this.error = e.response.data.error;
      if (this.error.find((item) => item == 'staff__suspended')) {
        this.$router.push('/blocked');
      } else if (this.error.find((item) => item == 'staff__not_found')) {
        this.notifi('notFound');
      } else if (this.error.find((item) => item == 'token__expired')) {
        this.$router.push('/expired-confirmation');
      } else if (this.error.find((item) => item == 'token__already_used')) {
        this.$router.push('/confirmation-email-already');
      } else {
        this.$router.push('/confirmation-error');
      }
    },
  },
};
</script>
